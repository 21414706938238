import React from "react";
import "./App.css";
import Container from "react-bootstrap/Container";
import LogoAnna from "./logoanna.png";
import LandImage from "./annaback.png";

export default function App() {
  return (
    <div>
      <Container fluid style={{ padding: "0" }}>
        {/* <NavigationBar /> */}
        <img src={LandImage} style={{ width:'100%', marginLeft:'auto', marginRight:'auto', display:'block ' }} />
        {/* <div style={{ textAlign: "center" }}>
          <br />
          <br />
          <img src={LogoAnna} style={{width: '6vw'}} />
          <br />
          <a style={{ fontSize: "3vw" }}>Anna Hilla</a>
          <br />
          <a style={{ fontSize: "3vw" }}>coach de salud integrativa</a>
          <br />
          <br />
          <a style={{ fontSize: "7vw" }}>PRÓXIMAMENTE</a>
          <br />
          <br />

          <a style={{ fontSize: "3vw" }}>Para más información contacta a:</a>
          <br />
          <a style={{ fontSize: "3vw" }}>info@annahilla.com</a>
        </div> */}
      </Container>
    </div>
  );
}
